<template>
  <div class="distribution_center">
    <div class="tabs">
      <div
        :class="['tab_item', index == activeIndex ? 'active' : '']"
        v-for="(item, index) in tabs"
        @click="changeTab(index)"
      >
        {{ item }}
      </div>
    </div>

    <div class="mt20" v-if="activeIndex == 0">
      <div class="earnings_aggregate" v-loading="loading">
        <div class="top_wrapper">
          <div class="title">
            <img
              class="icon_earnings"
              src="https://www.zizhihelp.com/upload/static/web/mine_shouyi.png"
              alt=""
            />
            收益总计：
          </div>

          <div class="total_amount">
            ¥{{ totalData.totalAmount.toFixed(2) }}
          </div>
        </div>

        <div class="desc">
          <div class="desc_item">
            <div class="text">已提现(元)</div>
            <div class="amount">{{ totalData.totalWithDraw.toFixed(2) }}</div>
          </div>

          <div class="desc_item">
            <div class="text">待提现(元)</div>
            <div class="amount">{{ totalData.residue.toFixed(2) }}</div>
          </div>
        </div>
      </div>

      <el-table
        v-loading="loading1"
        :data="billTableData"
        style="width: 100%"
        height="400px"
      >
        <el-table-column prop="name" label="用户信息">
          <template slot-scope="scope">
            <div class="flex alc">
              <!-- <img
                class="avatar"
                :src="scope.row.headimgurl || avatar"
                alt=""
              /> -->
              <el-avatar :size="42" :src="scope.row.headimgurl" class="mr10">
                <img
                  src="https://www.zizhihelp.com/upload/static/web/mine_touxiang.png"
                />
              </el-avatar>
              <div class="c333 fwb">
                {{ scope.row.nickname + " " + scope.row.biz }}
              </div>
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="cost" label="支付金额（元）"> </el-table-column>

        <el-table-column prop="payTime" label="支付时间"> </el-table-column>

        <el-table-column prop="award" label="分销奖励（元）">
          <template slot-scope="scope">
            <div class="ce3991d">{{ scope.row.award }}</div>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        class="pagination"
        hide-on-single-page
        layout="prev, pager, next"
        :total="billTotal"
        @current-change="handleBillCurrentChange"
        :current-page="billForm.pageNum"
        :page-size="billForm.pageSize"
      >
      </el-pagination>
    </div>

    <div v-if="activeIndex == 1">
      <div class="mt20 mb20">
        <el-button type="primary" @click="apply">申请提现</el-button>
      </div>

      <el-table
        v-loading="loading2"
        :data="withdrawTableData"
        style="width: 100%"
      >
        <el-table-column prop="name" label="提现方式">
          <template slot-scope="scope">
            <div class="flex alc">
              <img
                class="avatar"
                src="https://www.zizhihelp.com/upload/static/web/mine_tixian.png"
                alt=""
              />
              <div class="c333 fwb">
                提现到
                {{ scope.row.type }}
              </div>
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="applicationTime" label="申请时间">
        </el-table-column>

        <el-table-column prop="status" label="状态">
          <template slot-scope="scope">
            <div
              :class="
                scope.row.status == '待审核'
                  ? 'ce3991d'
                  : scope.row.status == '已驳回'
                  ? 'ceb4f3d'
                  : 'c28c445'
              "
            >
              {{ scope.row.status }}
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="amount" label="提现金额（元）">
          <template slot-scope="scope">
            <div class="ce3991d">{{ scope.row.amount.toFixed(2) }}</div>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        class="pagination"
        hide-on-single-page
        layout="prev, pager, next"
        :total="withdrawTotal"
        @current-change="handleWithdrawCurrentChange"
        :current-page="withdrawForm.pageNum"
        :page-size="withdrawForm.pageSize"
      >
      </el-pagination>

      <!-- 申请提现 -->
      <customDialog v-model="applyShow" title="申请提现" width="500px">
        <el-form
          :model="applyForm"
          :rules="rules"
          ref="applyForm"
          label-width="82px"
        >
          <el-form-item prop="way" label="提现方式">
            <el-select
              style="width: 100%"
              v-model="applyForm.way"
              placeholder="请选择审核状态"
              clearable
            >
              <el-option
                v-for="item in payWayOptions"
                :key="item.name"
                :label="item.name"
                :value="item.name"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item prop="amount" label="提现金额">
            <div class="code_input">
              <el-input
                v-model.trim="applyForm.amount"
                placeholder="请输入提现金额"
              >
              </el-input>

              <div
                class="getCode_btn"
                @click="applyForm.amount = totalData.residue"
              >
                全部提现
              </div>
            </div>
          </el-form-item>

          <el-form-item prop="code" label="待提现余额">
            <div class="ce3991d fz16 fwb">
              {{ totalData.residue.toFixed(2) }}
            </div>
          </el-form-item>
        </el-form>

        <div class="tips">
          <div class="tip_title">
            <img
              class="icon_tip"
              src="https://www.zizhihelp.com/upload/static/icon_tishi.png"
              alt=""
            />
            温馨提示
          </div>

          <div class="tips_text">1.请确保您的微信账号已通过实名认证；</div>
          <div class="tips_text">
            2.本次提现金额直接到达您的微信零钱钱包，提现不收取任何手续费；
          </div>
          <div class="tips_text">
            3.提现时间为即时到达，提现之后请即时查看微信零钱；
          </div>
          <div class="tips_text">
            4.若在提现过程中遇到任何问题，如资金未到账、提现失败等，请及时联系我们的客服团队。
          </div>
        </div>

        <div class="tar">
          <el-button @click="applyShow = false">取 消</el-button>
          <el-button type="primary" @click="submit"> 确 定 </el-button>
        </div>
      </customDialog>
    </div>
  </div>
</template>

<script>
/* 个人中心-提现管理 */
import {
  getAmount,
  getAmountHis,
  getWithdrawHis,
  applyWithdraw,
} from "@/api/personalCenter";
import local from "@/utils/local.js";

export default {
  data() {
    const validateAmount = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("提现金额不能为空"));
      }
      value = Number(value);
      if (!Number.isFinite(value)) {
        callback(new Error("请输入数字值"));
      } else {
        if (value < 0) {
          callback(new Error("提现金额不能小于0元"));
        } else if (value > this.totalData.residue) {
          callback(new Error("提现金额不能大于可提现金额"));
        } else {
          callback();
        }
      }
    };
    return {
      userInfo: {},
      tabs: ["我的账单", "提现管理"],
      activeIndex: 0,
      avatar: "https://www.zizhihelp.com/upload/static/web/mine_touxiang.png",

      billForm: {
        userId: "",
        pageNum: 1,
        pageSize: 9,
      },
      billTableData: [],
      billTotal: 0,

      withdrawForm: {
        userId: "",
        pageNum: 1,
        pageSize: 9,
      },
      withdrawTableData: [],
      withdrawTotal: 0,
      applyShow: false,
      applyForm: {
        amount: "",
        way: "微信",
      },
      payWayOptions: [
        {
          name: "微信",
        },
      ],
      rules: {
        way: [{ required: true, message: "请选择提现方式", trigger: "change" }],
        amount: [{ validator: validateAmount, trigger: "blur" }],
      },

      totalData: { residue: 0, totalAmount: 0, totalWithDraw: 0 },
      loading: false,
      loading1: false,
      loading2: false,
    };
  },

  methods: {
    /* 获取数据 */
    // 获取统计数据
    async getTotalData() {
      this.loading = true;
      const { data } = await getAmount({ userId: this.userInfo.id });
      this.totalData = data;
      this.loading = false;
    },
    // 获取账单列表
    async getBillData() {
      this.loading1 = true;
      const { data } = await getAmountHis(this.billForm);
      this.billTableData = data.records;
      this.billTotal = data.total;
      this.loading1 = false;
    },
    // 获取提现列表
    async getWithdrawData() {
      this.loading2 = true;
      const { data } = await getWithdrawHis(this.withdrawForm);
      this.withdrawTableData = data.records;
      this.withdrawTotal = data.total;
      this.loading2 = false;
    },

    /* 操作 */
    // 切换tab标签页
    changeTab(index) {
      this.activeIndex = index;
    },
    // 分页-我的账单
    handleBillCurrentChange(val) {
      this.billForm.pageNum = val;
      this.getListData();
    },
    // 分页-提现管理
    handleWithdrawCurrentChange(val) {
      this.withdrawForm.pageNum = val;
      this.getListData();
    },
    // 申请提现
    apply() {
      this.applyShow = true;
      this.$nextTick(() => {
        this.$refs["applyForm"].resetFields();
      });
      this.applyForm = {
        way: "微信",
        amount: "",
      };
    },
    // 提交申请提现表单
    async submit() {
      this.$refs["applyForm"].validate(async (valid) => {
        if (valid) {
          let { code, data } = await applyWithdraw(this.applyForm);
          if (code === 0) {
            this.$message.success(data);
            this.applyShow = false;
            this.getWithdrawData();
            this.getTotalData();
          }
        } else {
          return false;
        }
      });
    },

    /* 初始化 */
    __init__() {
      this.userInfo = local.get("userInfo");
      this.getTotalData();
      this.getBillData();
      this.getWithdrawData();
    },
  },

  created() {
    this.__init__();
  },
};
</script>

<style lang="less" scoped>
.distribution_center {
  .tabs {
    display: flex;
    align-items: center;
    font-size: 16px;

    .tab_item {
      color: #666666;
      margin-right: 66px;
      padding-bottom: 8px;
      cursor: pointer;
    }

    .active {
      color: #4f7bff;
      border-bottom: 2px solid #2e5edd;
    }
  }

  .avatar {
    width: 42px;
    height: 42px;
    border-radius: 50%;
    margin-right: 10px;
  }

  /deep/.el-table__header-wrapper th {
    background-color: #f9fafc !important;
    color: #4f7bff !important;
  }

  /deep/.el-button--primary {
    background-color: #4f7bff;
    border-color: #4f7bff;
  }

  .earnings_aggregate {
    width: 505px;
    padding: 20px;
    border-radius: 10px;
    background: linear-gradient(270deg, #4e7bff 0%, #5595ff 100%);
    box-shadow: 0px 0px 6px 0px rgba(157, 195, 221, 0.5);
    color: #fff;
    font-size: 18px;
    margin-bottom: 20px;

    .top_wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-weight: bold;
      margin-bottom: 12px;

      .title {
        display: flex;
        align-items: center;

        .icon_earnings {
          width: 20px;
          margin-right: 10px;
        }
      }
    }

    .desc {
      background-color: #fff;
      display: flex;
      align-items: center;
      color: #333;
      border-radius: 10px;
      box-shadow: 0px 0px 4px 0px rgba(157, 195, 221, 0.5);

      .desc_item {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 30px 0px;

        .text {
          font-size: 14px;
          color: #666;
          margin-bottom: 16px;
        }

        .amount {
          font-size: 18px;
          font-weight: bold;
        }
      }
    }
  }
}

.tips {
  .tip_title {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    .icon_tip {
      width: 20px;
      margin-right: 10px;
    }
  }

  .tips_text {
    line-height: 1.2;
    margin-bottom: 10px;
  }
}

.code_input {
  position: relative;

  .getCode_btn {
    color: #1472ff;
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }

  /deep/.el-input__inner {
    padding-right: 90px;
  }
}
</style>
